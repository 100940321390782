import { useState } from 'react'
import axios, { config } from './configure'
import FaucetForm from './components/FaucetForm'
import Header from './components/home/Header'
import Footer from './components/home/Footer'
import Alert from './components/home/Alert'
import './App.css'
import { IAlert } from './components/types'
import GoogleTagManager from "./components/GoogleTagManager";

function App() {
  const [showAlert, setShowAlert] = useState(false)
  const [alert, setAlert] = useState<IAlert>({
    type: undefined,
    message: ""
  })

  function toggleAlert(data: IAlert) {
    setShowAlert(true)
    setAlert(data)
  }

  return (
    <div className="app">
      <GoogleTagManager gtm_id={'GTM-TMBJPZP9'} />
      <Header />
      <FaucetForm axios = {axios} config = {config} showAlert={showAlert} setShowAlert={toggleAlert} />
      <Footer />
      {alert.type && <Alert alert={alert} showAlert={showAlert} setShowAlert={setShowAlert} />}
    </div>
  )
}

export default App
