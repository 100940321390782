const Footer = () => {
  return (
    <footer>
      <div className="px-5 py-8 sm:py-14 lg:py-16 xl:py-20">
        <div className="py-6 lg:py-12 xl:py-16 2xl:py-20">
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 flex-wrap">
            <div className="flex-1 flex items-center gap-2">
              <p>Whitepaper:</p>
              <a href="https://docs.xally.ai/" target="_blank" rel="noreferrer">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.99985 3C2.44765 3 2 3.44772 2 4V20C2 20.5523 2.44765 21 2.99985 21H20.9972C21.5494 21 21.9971 20.5523 21.9971 20V4C21.9971 3.44772 21.5494 3 20.9972 3H2.99985ZM5.99942 7H11.9985V13H5.99942V7ZM7.99912 9V11H9.99883V9H7.99912ZM17.9977 9H13.9982V7H17.9977V9ZM13.9982 13V11H17.9977V13H13.9982ZM5.99942 17V15H17.9977V17H5.99942Z" fill="white"/>
              </svg>
              </a>
            </div>

            <div className="flex-1 flex items-center md:justify-center gap-2">
              <p>Get in touch:</p>
              <div className="flex items-center gap-3">
                <a href="https://twitter.com/xallyai" rel="noreferrer" target="_blank">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.00391 2H1.00391L9.26477 13.0145L1.45386 21.9999H4.10389L10.4922 14.651L16.0039 22H23.0039L14.3956 10.5223L21.804 2H19.154L13.1682 8.88578L8.00391 2ZM17.0039 20L5.00391 4H7.00391L19.0039 20H17.0039Z" fill="white"/>
                  </svg>
                </a>
                <a href="https://t.me/xallyai_ann" rel="noreferrer" target="_blank">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.9986 22C6.4765 22 2 17.5228 2 12C2 6.47715 6.4765 2 11.9986 2C17.5206 2 21.9971 6.47715 21.9971 12C21.9971 17.5228 17.5206 22 11.9986 22ZM8.88914 13.1708L8.90179 13.1628C9.48144 15.0767 9.77125 16.0337 9.77125 16.0337C9.88351 16.3442 10.0378 16.3996 10.2251 16.3743C10.4123 16.3489 10.5117 16.2476 10.6339 16.1297C10.6339 16.1297 11.0301 15.7472 11.8226 14.9823L14.3731 16.8698C14.8378 17.1266 15.1734 16.9941 15.2888 16.4377L16.9463 8.61641C17.1293 7.88842 16.8084 7.59644 16.2439 7.82754L6.51233 11.5871C5.84842 11.854 5.85163 12.2255 6.39146 12.3911L8.88914 13.1708Z" fill="white"/>
                  </svg>
                </a>
                <a href="https://discord.com/invite/xally" rel="noreferrer" target="_blank">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.3047 5.33716C17.9359 4.71103 16.4822 4.2547 14.9648 4C14.7739 4.32899 14.5616 4.77471 14.413 5.12492C12.7992 4.89144 11.1969 4.89144 9.60527 5.12492C9.45671 4.77471 9.23388 4.32899 9.05348 4C7.52554 4.2547 6.07185 4.71103 4.71259 5.33716C1.96437 9.39111 1.22161 13.3495 1.59299 17.2549C3.41806 18.5815 5.17949 19.388 6.91013 19.9187C7.33456 19.3456 7.71656 18.73 8.0455 18.0827C7.41945 17.8492 6.82524 17.5627 6.25225 17.2231C6.40081 17.117 6.54937 17.0003 6.68731 16.8835C10.1464 18.4648 13.8932 18.4648 17.3098 16.8835C17.4584 17.0003 17.5964 17.117 17.7449 17.2231C17.1719 17.5627 16.5777 17.8492 15.9517 18.0827C16.2806 18.73 16.6626 19.3456 17.087 19.9187C18.8166 19.388 20.5886 18.5815 22.4042 17.2549C22.8604 12.7341 21.6816 8.80747 19.3047 5.33716ZM8.52298 14.8459C7.4831 14.8459 6.63423 13.9014 6.63423 12.7447C6.63423 11.5879 7.46187 10.6434 8.52298 10.6434C9.57344 10.6434 10.4329 11.5879 10.4117 12.7447C10.4117 13.9014 9.57344 14.8459 8.52298 14.8459ZM15.4955 14.8459C14.4555 14.8459 13.6055 13.9014 13.6055 12.7447C13.6055 11.5879 14.4343 10.6434 15.4955 10.6434C16.5459 10.6434 17.4054 11.5879 17.3841 12.7447C17.3841 13.9014 16.5565 14.8459 15.4955 14.8459Z" fill="white"/>
                  </svg>
                </a>
              </div>
            </div>

            <p className="flex-1 md:text-right whitespace-nowrap">
              ©2024 Copyright by Xally. All rights reserved
            </p>
          </div>
        </div>
      </div>

      <img src="/xally-text.png" alt="xally" className="w-full h-auto object-cover" />
    </footer>
  )
}

export default Footer;