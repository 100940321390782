export function addressTrimmed(address, length = 4) {
  if (!address)
    return "";

  if (address === "--" || address.length <= length)
    return address;

  return `${address.substring(0, length)}...${address.substring(
    address.length - length,
    address.length,
  )}`;
}

export async function copyText(text) {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(text);
  }
  else {
    const input = document.createElement("textarea");
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("");
    document.body.removeChild(input);
  }
}
