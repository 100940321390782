const Header = () => {
  return (
    <header className="h-[72px] bg-main-black-100">
      <div className="container h-full flex items-center">
        <img src="/logo.png" alt="xally" className="h-8 sm:h-9 w-auto" />
      </div>
    </header>
  )
}

export default Header