import { useState, useEffect } from 'react'
import queryString from 'query-string'
import { ethers } from 'ethers'

import ReCaptcha from './ReCaptcha'
import Banner from "./home/Banner"
import { addressTrimmed, copyText } from "../utils/index.js"
import { DropdownOption } from './types'
import { connectAccount } from './Metamask'
import { AxiosResponse } from 'axios'
import AddMetamaskChain from './home/AddMetamaskChain'

const xallyContractAddress = "0x5bD7E84218C7A7a8e4E27b3941724a44C6f5767E"

const Copy = (props: { value: string; className: string }) => {
    const [copied, setCopied] = useState(false)

    function copyFn() {
        setCopied(true)
        copyText(props.value)
        setTimeout(() => {
            setCopied(false)
        }, 2000)
    }
    return (
        <span onClick={() => copyFn()} className={'cursor-pointer ' + props.className}>
            { copied ? 'Copied' : 'Copy'}
        </span>
    )
}

const FaucetForm = (props: any) => {
    const [chain, setChain] = useState<number | null>(null)
    const [token, setToken] = useState<number | null>(null)
    const [widgetID, setwidgetID] = useState(new Map())
    const [recaptcha, setRecaptcha] = useState<ReCaptcha | undefined>(undefined)
    const [isV2, setIsV2] = useState<boolean>(true)
    const [chainConfigs, setChainConfigs] = useState<any>([])
    const [inputAddress, setInputAddress] = useState<string>("")
    const [couponId, setCouponId] = useState<string>("")
    const [address, setAddress] = useState<string | null>(null)
    const [faucetAddress, setFaucetAddress] = useState<string | null>(null)
    const [options, setOptions] = useState<DropdownOption[]>([])
    const [tokenOptions, setTokenOptions] = useState<DropdownOption[]>([]);
    const [balance, setBalance] = useState<string>("0")
    const [shouldAllowSend, setShouldAllowSend] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isFetchingBalance, setIsFetchingBalance] = useState<AbortController | null>(null)
    const [sendTokenResponse, setSendTokenResponse] = useState<{ txHash: string | null; message: string | null }>({
        txHash: null,
        message: null
    })

    // Update chain configs
    useEffect(() => {
        setRecaptcha(new ReCaptcha(
            props.config.SITE_KEY,
            props.config.ACTION,
            props.config.V2_SITE_KEY,
            setwidgetID
        ))
        updateChainConfigs()
        connectAccount(updateAddress, false)
    }, [])

    useEffect(() => {
        if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
                setIsV2(true)
                recaptcha?.loadV2Captcha(props.config.V2_SITE_KEY, widgetID)
            })
        }
    }, [props.config.V2_SITE_KEY, recaptcha, widgetID, window.grecaptcha])

    // Update balance whenver chain changes or after transaction is processed
    useEffect(() => {
        updateBalance()
    }, [chain, token, sendTokenResponse, chainConfigs])

    // Make REQUEST button disabled if either address is not valid or balance is low
    useEffect(() => {
        if(address) {
            if(BigInt(balance) > calculateBaseUnit(chainConfigs[token!]?.DRIP_AMOUNT, chainConfigs[token!]?.DECIMALS)) {
                setShouldAllowSend(true)
                return
            }
        }

        setShouldAllowSend(false)
    }, [address, balance])

    useEffect(() => {
        updateFaucetAddress()
    }, [chain, chainConfigs])

    useEffect(() => {
        let newOptions: DropdownOption[] = []

        chainConfigs?.forEach((chain: any, i: number) => {
            let item = <div className='select-dropdown'>
                <img alt = { chain.NAME[0] } src = { chain.IMAGE } />
                { chain.NAME }

                {
                    chain.CONTRACTADDRESS &&
                    <span style={{color: 'rgb(180, 180, 183)', fontSize: "10px", marginLeft: "5px"}}>
                        {
                            chainConfigs[chainToIndex(chain.HOSTID) || 0]?.NAME
                        }
                    </span>
                }
            </div>

            if(!chain.CONTRACTADDRESS) {
                newOptions.push({
                    label: item,
                    value: i,
                    search: chain.NAME
                })
            }
        })

        setOptions(newOptions)
        setChain(newOptions[0]?.value)
    }, [chainConfigs])

    useEffect(() => {
        let newOptions: DropdownOption[] = []

        chainConfigs?.forEach((chain: any, i: number) => {
            const { chain: ch } = getChainParams();

            let item = <div className='select-dropdown'>
                <img alt = { chain.NAME[0] } src = { chain.IMAGE } />
                { chain.ID === ch ? chain.TOKEN : chain.NAME }

                <span style={{color: 'rgb(180, 180, 183)', fontSize: "10px", marginLeft: "5px"}}>
                    {
                        chain.CONTRACTADDRESS ?
                        "ERC20" :
                        "Native"
                    }
                </span>
            </div>

            if((chain.CONTRACTADDRESS && chain.HOSTID === ch) || chain.ID === ch) {
                newOptions.push({
                    label: item,
                    value: i,
                    search: chain.NAME
                })
            }
        })

        setTokenOptions(newOptions)
        setToken(newOptions[0]?.value)
    }, [chainConfigs, chain])

    const getConfigByTokenAndNetwork = (token: any, network: any): number => {
        let selectedConfig = 0;

        try {
            token = token?.toUpperCase();
            network = network?.toUpperCase();

            chainConfigs.forEach((chain: any, i: number): any => {
                if(chain.TOKEN === token && chain.HOSTID === network) {
                    selectedConfig = i;
                }
            })
        } catch(err: any) {
            console.log(err)
        }

        return selectedConfig;
    }

    let totalTokens: boolean = tokenOptions?.length === 0;

    useEffect(() => {
        const query = queryString.parse(window.location.search)

        const { address, subnet, erc20 } = query

        const tokenIndex: number = getConfigByTokenAndNetwork(erc20, subnet)

        if(typeof address === "string") {
            updateAddress(address)
        }

        if(typeof subnet === "string") {
            setChain(chainToIndex(subnet))
            if(typeof erc20 === "string") {
                setToken(tokenIndex)
            }
        } else {
            setChain(0)
        }
    }, [window.location.search, options, totalTokens])

    // API calls
    async function updateChainConfigs(): Promise<void> {
        const response: AxiosResponse = await props.axios.get(
            props.config.api.getChainConfigs
        )
        setChainConfigs(response?.data?.configs)
    }

    function getChainParams(): {chain: string, erc20: string} {
        let params = {
            chain: chainConfigs[chain!]?.ID,
            erc20: "XALLYTOKEN"
        }

        return params
    }

    async function updateBalance(): Promise<void> {
        // Abort pending requests
        const controller = new AbortController();
        if(isFetchingBalance) {
            isFetchingBalance.abort()
        }
        setIsFetchingBalance(controller)

        if((chain || chain === 0) && chainConfigs.length > 0) {
            let { chain, erc20 } = getChainParams()

            const response: AxiosResponse = await props.axios.get(props.config.api.getBalance, {
                params: {
                    chain,
                    erc20
                },
                signal: controller.signal
            })

            if(response?.data?.balance || response?.data?.balance === 0) {
                setBalance(response?.data?.balance)
            }
        }
    }

    async function updateFaucetAddress(): Promise<void> {
        if((chain || chain === 0) && chainConfigs.length > 0) {
            let { chain } = getChainParams()

            const response: AxiosResponse = await props.axios.get(props.config.api.faucetAddress, {
                params: {
                    chain
                }
            })

            if(response?.data) {
                setFaucetAddress(response?.data?.address)
            }
        }
    }

    function calculateBaseUnit(amount: string = "0", decimals: number = 18): bigint {
        const parsedNumber = parseFloat(amount);

        if (!isFinite(parsedNumber)) {
            throw new Error("Invalid number input for formatting base unit: " + amount);
        }

        const formattedNumber = parsedNumber.toFixed(decimals);
        const [integerPart, decimalPart] = formattedNumber.split('.');

        const bigInteger = BigInt(integerPart);
        const bigDecimal = BigInt(decimalPart || '0');

        const finalAmount = bigInteger * BigInt(10 ** decimals) + bigDecimal;
        return finalAmount
    }

    function calculateLargestUnit(amount: string = "0", decimals: number = 18): string {
        let base = "1"
        for(let i = 0; i < decimals; i++) {
            base += "0"
        }
        return (BigInt(amount) / BigInt(base)).toString()
    }

    function chainToIndex(id: any): number | null {
        if(chainConfigs?.length > 0) {
            if(typeof id === "string") {
                id = id.toUpperCase()
            }
            let index: number = 0
            chainConfigs.forEach((chain: any, i: number) => {
                if(id === chain.ID) {
                    index = i
                }
            })
            return index
        } else {
            return null
        }
    }

    function updateAddress(addr: any): void {
        setInputAddress(addr!)

        if (addr) {
            if (ethers.utils.isAddress(addr)) {
                setAddress(addr)
            } else {
                setAddress(null)
            }
        } else if (address != null) {
            setAddress(null)
        }
    }

    async function getCaptchaToken(index: number = 0): Promise<{token?:string, v2Token?: string}> {
        const { token, v2Token } = await recaptcha!.getToken(isV2, widgetID, index)
        return { token, v2Token }
    }

    const ifCaptchaFailed = (data: any, index: number = 0, reload: boolean = false) => {
        if(typeof data?.message === "string") {
            if(data.message.includes("Captcha verification failed")) {
                setIsV2(true)
                recaptcha?.loadV2Captcha(props.config.V2_SITE_KEY, widgetID, index, reload);
            }
        }
    }

    async function sendToken(): Promise<void> {
        if(!shouldAllowSend) {
            return
        }
        let data: any
        try {
            setIsLoading(true)

            const { token, v2Token } = await getCaptchaToken()
            let { chain, erc20 } = getChainParams()

            const response = await props.axios.post(props.config.api.sendToken, {
                address,
                token,
                v2Token,
                chain,
                erc20,
                couponId
            })
            data = response?.data
        } catch(err: any) {
            data = err?.response?.data || err
        }
        if (data?.txHash) {
            props.setShowAlert({
                type: "success",
                message: "You received 1 XALLY TESTNET. You can request for another XALLY in next 24 hours."
            })
            resetRecaptcha()
        } else {
            props.setShowAlert({
                type: "error",
                message: data?.message || "Request failed! Please try again later."
            })
        }

        ifCaptchaFailed(data)

        setSendTokenResponse({
            txHash: data?.txHash,
            message: data?.message
        })

        setIsLoading(false)
    }

    const resetRecaptcha = (): void => {
        // setIsV2(false)
        recaptcha!.resetV2Captcha(widgetID)
    }

    const back = (): void => {
        resetRecaptcha()
        setSendTokenResponse({
            txHash: null,
            message: null
        })
    }

    return (
      <div className='mx-auto max-w-2xl bg-main-black-300 min-h-[60svh]'>
        <Banner />
        <div className="py-10">
            <h1 className='text-4xl sm:text-5xl xl:text-6xl text-center'>
                Xally Testnet Faucet
            </h1>
            <div className="px-4 sm:px-10 lg:px-16 xl:px-[70px]">
                <p className='text-center mt-6'>
                    Faucet Balance: {calculateLargestUnit(balance, chainConfigs[token!]?.DECIMALS)} {chainConfigs[token!]?.NAME}
                </p>
                <p className='text-main-orange-500 mt-4 mb-12 text-center'>
                    Drop limitation: 1 {chainConfigs[token!]?.NAME} per day
                </p>
                <div className='flex flex-col'>
                    <div className='flex flex-col gap-y-2'>
                        <label htmlFor="inputAddress" className='font-semibold'>
                            Wallet address:
                        </label>
                        <input
                            id='inputAddress'
                            type="text"
                            placeholder='Enter or paste your wallet address (0x...)'
                            className='min-h-12 w-full outline-none bg-transparent py-4 text-base text-main-black-900 border-b border-main-black-200'
                            value={inputAddress || ""}
                            onChange={(e) => updateAddress(e.target.value)}
                            autoFocus
                        />
                        <div className="flex justify-center items-center my-4 min-h-20">
                            <div className='v2-recaptcha' />
                        </div>
                        <button className='btn' onClick={sendToken} disabled={!shouldAllowSend}>
                            {
                                isLoading ? (
                                    <span className='w-6 h-6'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z" opacity=".5"/>
                                            <path fill="currentColor" d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z">
                                                <animateTransform attributeName="transform" dur="1s" from="0 12 12" repeatCount="indefinite" to="360 12 12" type="rotate"/>
                                            </path>
                                        </svg>
                                    </span>
                                ) : (
                                    <span> Drop Request </span>
                                )
                            }
                        </button>
                    </div>
                    {
                        sendTokenResponse.txHash && (
                            <div className='pt-6 pb-2'>
                                <p>Your transaction hash</p>
                                <div className='py-4 flex items-center justify-between text-lg gap-4'>
                                    <div className='flex items-center gap-2'>
                                        <p>{ addressTrimmed(sendTokenResponse.txHash) }</p>
                                        <Copy value={sendTokenResponse.txHash || ''} className='text-main-orange-100 text-sm' />
                                    </div>
                                    {chainConfigs[token!]?.EXPLORER && (
                                        <a
                                            href={`${chainConfigs[token!]?.EXPLORER}/tx/${sendTokenResponse.txHash}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <span className='text-white hover:text-gray-300'>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 3C14 2.44772 14.4477 2 15 2H21C21.5523 2 22 2.44771 22 3L22 9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55229 20 9L20 5.41421L13.7071 11.7071C13.3166 12.0976 12.6834 12.0976 12.2929 11.7071C11.9024 11.3166 11.9024 10.6834 12.2929 10.2929L18.5858 4H15C14.4477 4 14 3.55228 14 3ZM7.7587 4L10 4C10.5523 4 11 4.44772 11 5C11 5.55228 10.5523 6 10 6H7.8C6.94342 6 6.36113 6.00078 5.91104 6.03755C5.47262 6.07337 5.24842 6.1383 5.09202 6.21799C4.7157 6.40973 4.40973 6.71569 4.21799 7.09202C4.1383 7.24842 4.07337 7.47262 4.03755 7.91104C4.00078 8.36113 4 8.94342 4 9.8V16.2C4 17.0566 4.00078 17.6389 4.03755 18.089C4.07337 18.5274 4.1383 18.7516 4.21799 18.908C4.40973 19.2843 4.7157 19.5903 5.09202 19.782C5.24842 19.8617 5.47262 19.9266 5.91104 19.9624C6.36113 19.9992 6.94342 20 7.8 20H14.2C15.0566 20 15.6389 19.9992 16.089 19.9624C16.5274 19.9266 16.7516 19.8617 16.908 19.782C17.2843 19.5903 17.5903 19.2843 17.782 18.908C17.8617 18.7516 17.9266 18.5274 17.9624 18.089C17.9992 17.6389 18 17.0566 18 16.2V14C18 13.4477 18.4477 13 19 13C19.5523 13 20 13.4477 20 14V16.2413C20 17.0463 20 17.7106 19.9558 18.2518C19.9099 18.8139 19.8113 19.3306 19.564 19.816C19.1805 20.5686 18.5686 21.1805 17.816 21.564C17.3306 21.8113 16.8139 21.9099 16.2518 21.9558C15.7106 22 15.0463 22 14.2413 22H7.75868C6.95372 22 6.28936 22 5.74817 21.9558C5.18608 21.9099 4.66937 21.8113 4.18404 21.564C3.43139 21.1805 2.81947 20.5686 2.43597 19.816C2.18868 19.3306 2.09012 18.8139 2.04419 18.2518C1.99998 17.7106 1.99999 17.0463 2 16.2413V9.7587C1.99999 8.95373 1.99998 8.28937 2.04419 7.74817C2.09012 7.18608 2.18868 6.66937 2.43597 6.18404C2.81947 5.43139 3.43139 4.81947 4.18404 4.43597C4.66937 4.18868 5.18608 4.09012 5.74818 4.04419C6.28937 3.99998 6.95373 3.99999 7.7587 4Z" fill="currentColor"/>
                                                </svg>
                                            </span>
                                        </a>
                                    )}
                                </div>
                            </div>
                        )
                    }
                    <div className='border-y border-main-black-200 py-6 mt-6'>
                        <p className='text-center text-main-orange-100'>
                            Add Xally Testnet to your browser wallet extension or visit the block explorer.
                        </p>
                        <div className="grid grid-cols-2 gap-4 sm:gap-6 mt-4 sm:mt-6 mb-2">
                            {chainConfigs[token!] && <AddMetamaskChain chain={chainConfigs[token!]} />}
                            {chainConfigs[token!]?.EXPLORER && (
                                <a className='btn' href={chainConfigs[token!].EXPLORER} target='_blank' rel="noreferrer">
                                    <img src="/xally.png" alt="xally" />
                                    <span>View Block Explorer</span>
                                </a>
                            )}
                        </div>
                    </div>
                    <div className='py-6'>
                        <p>
                            Please send the remaining coins to the following faucet address after you completing the test.
                        </p>
                        <div className='py-4 flex items-center justify-between text-lg gap-4'>
                            <p>{ addressTrimmed(faucetAddress) }</p>
                            <Copy value={faucetAddress || ''} className='text-main-orange-100' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
}

export default FaucetForm
