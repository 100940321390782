import { useEffect, useMemo } from "react";
import { IAlert } from "../types"

const Alert = (props: { showAlert: boolean; alert: IAlert, setShowAlert: any }) => {
  useEffect(() => {
    if (props.showAlert) {
      setTimeout(() => {
        props.setShowAlert(false)
      }, 4000)
    }
  }, [props.showAlert])

  const alertStyles = useMemo(() => {
    return {
      background: props.alert.type === 'error' ? 'bg-[#edccc9]' : 'bg-[#c5f7dc]',
      text: props.alert.type === 'error' ? 'text-[#bf514d]' : 'text-[#349861]'
    }
  }, [props.alert])


  return (
    ((props.showAlert && props.alert.type) ? 
    <div className={`fixed top-20 right-6 sm:right-8 z-[1000] min-h-14 max-w-md w-[calc(100%-48px)] p-4 rounded-lg ${alertStyles.background}`}>
      <div className={`${alertStyles.text} h-full flex items-center gap-3`}>
        { props.alert.type === 'error' &&
          <span className="shrink-0">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 7C12.5523 7 13 7.44772 13 8V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V8C11 7.44772 11.4477 7 12 7ZM11 16C11 15.4477 11.4477 15 12 15H12.01C12.5623 15 13.01 15.4477 13.01 16C13.01 16.5523 12.5623 17 12.01 17H12C11.4477 17 11 16.5523 11 16Z" fill="currentColor"/>
            </svg>
          </span>
        }
        { props.alert.type === 'success' &&
          <span className="shrink-0">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM17.2071 8.29289C17.5976 8.68342 17.5976 9.31658 17.2071 9.70711L11.2071 15.7071C10.8166 16.0976 10.1834 16.0976 9.79289 15.7071L6.79289 12.7071C6.40237 12.3166 6.40237 11.6834 6.79289 11.2929C7.18342 10.9024 7.81658 10.9024 8.20711 11.2929L10.5 13.5858L15.7929 8.29289C16.1834 7.90237 16.8166 7.90237 17.2071 8.29289Z" fill="currentColor"/>
            </svg>
          </span>
        }
        <p>{ props.alert.message }</p>
      </div>
    </div> : null)
  )
}

export default Alert