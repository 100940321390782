import {useEffect} from 'react';

export default function useExternalScripts({url, content}: { url?: string, content?: string }) {
    useEffect(() => {
        const head = document.querySelector("head");
        if (!head) {
            return
        }
        const script = document.createElement("script");

        if (url) {
            script.setAttribute("src", url);
        } else if (content) {
            script.innerHTML = content
        } else {
            return
        }

        head.appendChild(script);

        return () => {
            head.removeChild(script);
        };
    }, [url, content]);
};
