import { ethers } from 'ethers'

const AddMetamaskChain = (props: { chain: any }) => {
  function addEthereumChain() {
    try {
      const chain = props.chain
      if (!chain) {
        console.log(`Chain config not found. Please try again`)
        return;
      }
      if (!window?.ethereum ) {
        window.open('https://metamask.io/download', '_blank')
        console.log(`You have not installed the Metamask extension yet. Please install it.`)
        return;
      }
      const hexChainId = ethers.utils.hexValue(chain.CHAINID)
      window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: hexChainId,
          chainName: chain.NAME,
          nativeCurrency: {
              name: chain.NAME,
              symbol: chain.TOKEN,
              decimals: chain.DECIMALS
          },
          rpcUrls: [chain.RPC],
          blockExplorerUrls: chain.EXPLORER ? [chain.EXPLORER] : null
        }]
      })
    } catch (error) {
      console.log(`Add chain failed`)
    }
  }
  return (
    <button className='btn' onClick={() => addEthereumChain()}>
      <img src="/metamask.png" alt="metamask" />
      <span>Add Testnet to Metamask</span>
    </button>
  )
}

export default AddMetamaskChain
